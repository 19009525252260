<div class="row">
    <div class="col s12">
        <div class="container">
            <div id="login-page" class="row">
                <div class="col s12 m6 l4 z-depth-4 card-panel border-radius-6 login-card bg-opacity-8">
                    <form autocomplete="off" class="login-form" #form="ngForm" (submit)="onSubmit(form)">
                        <div class="row">
                            <div class="input-field col s12" style="text-align: right; padding-top: 5px;">
                                <img (click)="onVietNam()" width="30" height="30" src="/assets/image/vietnam.png"
                                    title="Việt Nam" alt="Việt Nam" style="margin-right: 10px; cursor: pointer;">
                                <img (click)="onEnglish()" width="30" height="30" src="/assets/image/english.png"
                                    title="English" alt="English" style="cursor: pointer;">
                            </div>
                        </div>
                        <div class="row">
                            <div class="input-field col s12" style="text-align: center; padding-top: 5px;">
                                <img width="300" height="150" src="/assets/image/dulich_logo.png"
                                    title="Cổng xác thực để truy cập hệ sinh thái số"
                                    alt="Cổng xác thực để truy cập hệ sinh thái số">
                            </div>
                        </div>
                        <div class="row margin">
                            <div class="input-field col s12">
                                <i class="material-icons prefix pt-2">email</i>
                                <input type="email" placeholder="{{DownloadService.TitleTaiKhoan}}" name="Email"
                                    [(ngModel)]="ThanhVienService.formData.Email">
                            </div>
                        </div>
                        <div class="row">
                            <div class="input-field col s12">
                                <button type="submit" [disabled]="!ThanhVienService.formData.Email"
                                    class="btn waves-effect waves-light border-round gradient-45deg-purple-deep-orange col s12">{{DownloadService.TitleQuenMatKhauButton}}</button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="input-field col s6 m6 l6">
                                <p class="margin medium-small">
                                    <a href="{{domainName}}Login"
                                        title="{{DownloadService.TitleDangNhap}}">{{DownloadService.TitleDangNhap}}</a>
                                </p>
                            </div>
                            <div class="input-field col s6 m6 l6">
                                <p class="margin right-align medium-small">
                                    <a href="{{domainName}}Register"
                                        title="{{DownloadService.TitleDangKy}}">{{DownloadService.TitleDangKy}}</a>
                                </p>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="isShowLoading"></app-loading>